import React from "react";
import { Link } from "react-router-dom";
import { Box, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import "./components.css";
import sponsorImage from './sponsor_3.png'; 
import sponsorImage2 from './sponsor_2.png'; 

function HeroSection() {

    return (
        <div className="background hero-container" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '100vh',
        }}>
            <div className="content-overlay" style={{
                textAlign: 'center',
                padding: '20px 0',
                flexGrow: 1,
            }}>
                <h1 className="gradient-text" style={{ fontWeight: 700, margin: '0.5em 0' }}>Be faster with karting specialised artificial intelligence</h1>
                <p className="responsive-h4" style={{ fontWeight: "300", fontSize: "24px", margin: '0.5em 0', color: "white" }}>Finds for you the Best Setup for Every Scenario. Helps you improve your driving style - all powered by AI</p>
                <div className="hero-btns" style={{ display: 'flex', justifyContent: 'center', gap: '1em' }}>
                    {localStorage.getItem("access_token") ? (
                        <Link to="/racing-space-try" className="insertdata">
                            <Button variant="contained" color="light" onClick={() => console.log("hey")}>
                                Try Free
                            </Button>
                        </Link>
                    ) : (
                        <Link to="/racing-space-try" className="insertdata">
                            <Button variant="contained" color="light" onClick={() => console.log("hey")}>
                              Try Free 
                            </Button>
                        </Link>
                    )}
                    <ScrollLink to="second-part-home" smooth={true} duration={500}>
                        <Button variant="outlined" color="light">
                            Learn More
                        </Button>
                    </ScrollLink>
                </div>
            </div>

            {/* Section for displaying logos */}
            <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                width: '100%',
                bgcolor: 'transparent',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',  // Center vertically
                gap: { xs: '4.4em', md: '5em' },  // Adjust gap for different screen sizes
                mb: 2,  // Bottom margin for spacing
                mt: { xs: 2, md: 8 },  // Top margin to create space on larger screens
                flexWrap: 'wrap'  // Allow logos to wrap on smaller screens
            }}>
                {/* Kart Republic Logo */}
                <Box component="img" src={`${process.env.PUBLIC_URL}/images/kart-republic-logo-white.png`} alt="Kart Republic Logo" sx={{ width: { xs: 100, md: 120 }, height: 'auto', mb: { xs: 2, md: 0 } }} />
                
                {/* Sponsor Logos */}
                <Box component="img" src={sponsorImage} alt="Sponsor Logo" sx={{ width: { xs: 100, md: 120 }, height: 'auto', mb: { xs: 2, md: 0 } }} />
                {/*<Box component="img" src={sponsorImage2} alt="Sponsor Logo" sx={{ width: { xs: 85, md: 120 }, height: 'auto', mb: { xs: 2, md: 0 } }} />*/}
            </Box>
        </div>
    );
}

export default HeroSection;
