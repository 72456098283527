import '../components.css';

import React, { useEffect, useState } from 'react';

import { LineChart } from '@mui/x-charts/LineChart';
import axios from 'axios';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite";

export default function GridDemo({ race, driverSessions, hoveredData, setHoveredData, trackIndex, setTrackIndex, day }) {
  const [dataset, setDataset] = useState([]);
  const [highlightedItem, setHighLightedItem] = useState({
    seriesId: 'test-series',
    dataIndex: -1,
  });
  const selectedTeam = myUserStore.getSelectedTeam;

  useEffect(() => {
    if (highlightedItem && dataset[0]?.data[highlightedItem.dataIndex]) {
      const { Latitude, Longitude } = dataset[0]?.data[highlightedItem.dataIndex];
      setHoveredData({ Latitude, Longitude });
      console.log("passed lat long", { Latitude, Longitude });
    }
  }, [highlightedItem]);

  const getDeltaTime = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/telemetry_delta_time_graph?race=${race}&day=${day}&team_id=${selectedTeam.id}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            'Content-Type': 'application/json'
          },
        }
      )
      .then((userResponse) => {
        if (Array.isArray(userResponse.data)) {
          const modifiedData = userResponse.data.map((item) => {
            if (item.data && item.data.length > 0) {
              item.data[0].Delta_Time = item.data[1].Delta_Time;
            }
            return item;
          });
          setDataset(modifiedData);
        } else {
          console.error("Unexpected data format received:", userResponse.data);
          setDataset([]);
        }
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  };

  useEffect(() => {
    getDeltaTime();
  }, [driverSessions]);

  if (!Array.isArray(dataset) || dataset.length === 0 || !dataset[0]?.data) {
    return (
      <div className="grid-demo-no-data-container">
        <p className="grid-demo-no-data-text">Please select two or more laps</p>
        <i className="grid-demo-info-icon fas fa-info-circle"></i>
      </div>
    );
  }

  const xAxisData = dataset[0].data.map((item) => item.Percentage);
  const seriesData = dataset.slice(0, -1).map((driverData, driverIndex) => {
    return {
      id: `series-${driverIndex}`,
      data: driverData?.data?.map((item) => item?.Delta_Time),
      label: `${driverData.surname} (lap ${driverData.comparison_lap})`, // Updated to use surname
      showMark: false,
      tooltip: (data, index) => {
        const surname = driverData.surname; // Using surname instead of email
        const lapNumber = driverData.reference_lap; // Assuming `driverData` contains `lap` information
        return `${surname} (Lap ${lapNumber}): ${data.toFixed(5)}`; // Updated to display surname
      },
    };
  });

  const handleInteraction = (event) => {
    const chart = event.currentTarget.getBoundingClientRect();
    const gridElement = event.currentTarget.querySelector('g.MuiChartsGrid-root');
    const gridRect = gridElement.getBoundingClientRect();

    const x = event.clientX || event.touches[0].clientX;
    const y = event.clientY || event.touches[0].clientY;

    const isWithinXAxis = x >= gridRect.left && x <= gridRect.right;
    const isWithinYAxis = y >= gridRect.top && y <= gridRect.bottom;

    if (isWithinXAxis && isWithinYAxis) {
      const closestIndex = Math.round((x - gridRect.left) / (gridRect.width) * ((dataset[0]?.data[99]?.Index / 32) - 1));
      setTrackIndex(closestIndex);
      console.log("closestIndex", closestIndex);

      if (dataset[0]?.data[closestIndex]) {
        setHighLightedItem({ seriesId: 'mySeries', dataIndex: closestIndex });
      }
    }
  };

  return (
    <>
      {dataset.length > 0 && (
        <div
          className="grid-demo-chart-container"
          onMouseMove={handleInteraction}
          onTouchMove={handleInteraction}

        >
          <LineChart
            xAxis={[{ data: xAxisData }]}
            yAxis={[{ format: (value) => value.toFixed(5) }]}
            series={[...seriesData]}
            height={300}
            style={{ marginTop: 10 }}
            margin={{ left: 20, right: 20, top: 40, bottom: 20 }} // Increased top margin to accommodate legend
            grid={{ vertical: true, horizontal: true, color: '#ccc' }}
            axisHighlight={{
              x: 'line',
              y: 'line',
            }}
            highlightedItem={highlightedItem}
            onHighlightChange={setHighLightedItem}
            responsive={{ maintainAspectRatio: false }}
            tooltip={{
              style: {
                backgroundColor: '#fff',
                color: '#000',
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '5px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                zIndex: 9999, // Ensure tooltip is above other charts
              },
              position: 'top',
              avoidOverlap: true,
            }}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'top', horizontal: 'middle' },
                padding: 5, // Adjust padding to ensure legend does not overflow
              },
            }}
          />
        </div>
      )}
    </>
  );
}
