import { Box, Skeleton, Stack } from '@mui/material';
import { Button, Chip, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChatIcon from '@mui/icons-material/Chat';
import ChatMessage from 'components/Chat/Message';
import ChatMessageLoader from 'components/Chat/MessageLoader';
import ChatSuggestion from '../Suggestions';
import Drawer from './Drawer';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Markdown from 'marked-react';
import Paper from '@mui/material/Paper';
import Pricing from './Pricing';
import StarIcon from '@mui/icons-material/Star';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Writer from 'components/Chat/Writer';
import myUserStore from '../../stores/UserStore';
import { toast } from "react-toastify";

const chatTypes = [
    {
        title: 'AiMotor 1',
        description: 'Great for Personal Space',
        type: 'normal',
        icon: <FlashOnIcon fontSize="small" />,
        activeFor: ['Growth', 'Pro', 'Team'],
        userPlan: ['Growth'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        }
    },
    {
        title: 'AiMotor 2',
        description: 'Advanced model for Racing Teams',
        type: 'advanced',
        icon: <StarIcon fontSize="small" />,
        activeFor: ['Pro', 'Team'],
        userPlan: ['Growth', 'Pro', 'Team'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        }
    }
]

const Chat = () => {
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [allChatMessages, setAllChatMessages] = useState({});
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [openPricing, setOpenPricing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chatType, setChatType] = useState(chatTypes[0])
    const selectedTeam = myUserStore.getSelectedTeam;
    const showPersonalButton = myUserStore.isPersonalSpace;
    const userPlan = myUserStore.planCode
    const [isMobile, setIsMobile] = useState(false);
    const [isResponseStreaming, setIsResponseStreaming] = useState(false);  // New state to manage response streaming
    const [incomingFragments, setIncomingFragments] = useState([]); // Frammenti da scrivere

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const chatContainerRef = useRef(null);

    const getChatByPlan = (userPlan) => {
        const getChatToBeSeleced = chatTypes.find(c => c.userPlan.includes(userPlan))
        if (getChatToBeSeleced) {
            setChatType(getChatToBeSeleced)
        } else {
            setChatType(chatTypes[0])
        }
    }

    const handleMenuItemClick = (model) => {
        const getChatToBeSeleced = chatTypes.find(c => c.title === model)

        if (getChatToBeSeleced) {
            const canSelect = getChatToBeSeleced.activeFor.includes(userPlan)
            if (canSelect) {
                setChatType(getChatToBeSeleced)
                handleClose();
            } else {
                setOpenPricing(true)
            }
        }
    };

    useEffect(() => {
        if (!selectedChatId) {
            handleLatestChat()
        } else {
            getChatById(selectedChatId);
        }
    }, [selectedChatId]);

    useEffect(() => {
        getChatByPlan(userPlan)
    }, [userPlan])

    const getChatById = async (chatId) => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/get_conversations/?chat_id=${chatId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
                "Accept": "application/json",
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
            }

        });
        const data = await response.json();
        setAllChatMessages(prevMessages => ({ ...prevMessages, [chatId]: { name: '', messages: data.messages, createdAt: new Date() } }));
        return data;
    };

    const generateChatId = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/create_conversation`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        const data = await response.json();
        const conversation_id = data.conversation_id;
        return conversation_id;
    };

    const getlatestchat = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/get_last_conversation/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        let data
        if (response?.ok) {
            data = await response.json();
        } else {
            data = { _id: await generateChatId() }
        }
        return data;
    };

    const saveLatestSystemMessage = (message, role = "system") => {
        setAllChatMessages(prevMessages => {
            const updatedChat = prevMessages[selectedChatId];
            if (!updatedChat) return prevMessages;
            const updatedMessages = [...updatedChat.messages, { message: message, role }];
            return {
                ...prevMessages,
                [selectedChatId]: { ...updatedChat, messages: updatedMessages }
            };
        });
    };

    const handleMessageComplete = (message, role) => {
        saveLatestSystemMessage(message)
        setIncomingFragments([])
        setIsResponseStreaming(false)
    }

    const handleMessageSubmit = async (message) => {
        if (!selectedChatId) {
            console.warn("No chat ID selected.");
            return;
        }

        setIsLoading(true);
        const encodedMessage = encodeURIComponent(message);
        saveLatestSystemMessage(message, "user")
        setIsResponseStreaming(true);  // Indicate streaming has started

        // Add user message to state
        try {
            const eventSource = new EventSource(`${process.env.REACT_APP_API_DOMAIN}/aimotor_2?request=${encodedMessage}&conversation_id=${selectedChatId}&team_id=${selectedTeam.id}&day=${chatType.queryParams.day}&day_team=${chatType.queryParams.day_team}&personal_retrieval_limit=${chatType.queryParams.personal_retrieval_limit}&team_retrieval_limit=${chatType.queryParams.team_retrieval_limit}&token=${localStorage.getItem("access_token")}`);

            eventSource.onmessage = async (event) => {
                setIsLoading(false);
                if (event.data === "[DONE]") {
                    // connection is completed
                    setIncomingFragments(prev => [...prev, JSON.parse('"[DONE]"')]);
                    eventSource.close();
                } else {
                    setIncomingFragments(prev => [...prev, JSON.parse(event.data)]);
                }
                return event;
            };

            eventSource.onerror = (err) => {
                eventSource.close();
                setIsLoading(false)
            };

        } catch (error) {
            setIsLoading(false);
            setIsResponseStreaming(false);
            toast.error("Error while generating message, please try again", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
            console.error("Error querying LLM:", error);
        }
    };

    const ScrollChatBottom = useCallback(() => {
        const anchorEl = chatContainerRef.current;

        if (anchorEl) {
            anchorEl.scrollTop = anchorEl.scrollHeight - anchorEl.clientHeight;
        }
    }, [allChatMessages?.messages?.length, chatContainerRef])

    const handleNewChat = async () => {
        const newChatId = await generateChatId();
        setAllChatMessages(prevMessages => ({ ...prevMessages, [newChatId]: { name: '', messages: [], createdAt: new Date() } }));
        setSelectedChatId(newChatId);
    };

    const handleLatestChat = async () => {
        const { _id, messages = [] } = await getlatestchat();
        setAllChatMessages(prevMessages => ({ ...prevMessages, [_id]: { name: '', messages, createdAt: new Date() } }));
        setSelectedChatId(_id);
        ScrollChatBottom(3000);
    };

    const handleSuggestionClick = (message) => {
        handleMessageSubmit(message)
    };

    const handleSelectPreviousChat = (chatId) => setSelectedChatId(chatId);

    useEffect(() => {
        ScrollChatBottom()
    }, [ScrollChatBottom]);

    useEffect(() => {
        if (selectedChatId && chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [selectedChatId, allChatMessages]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box className="chat-wrapper" container={true} justifyContent="center">
                <Box sx={{ position: 'absolute', top: '16px', left: '16px', display: 'flex', alignItems: 'center' }}>
                    <Button
                        aria-controls={open ? 'version-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant="text"
                        sx={{ color: '#0D47A1', textTransform: 'none', fontSize: '22px', fontWeight: 'bold' }}
                    >
                        {chatType.title}
                        <ArrowDropDownIcon />
                    </Button>
                    <Menu
                        id="version-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ mt: 1 }}
                    >
                        {chatTypes.map((type) => (
                            <MenuItem key={type.title} onClick={() => handleMenuItemClick(type.title)}>
                                <ListItemIcon>
                                    {type.icon}
                                </ListItemIcon>
                                <ListItemText primary={type.title} secondary={type.description} />
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                <Box sx={{ height: "100%", width: '100%', pt: 10 }} display={'flex'} flexDirection={'column'} mx={'auto'}>
                    <div id="chat-container" style={{ flex: '1', overflowY: 'auto', textAlign: 'center', display: selectedChatId && allChatMessages[selectedChatId]?.messages?.length ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box>
                            <ChatSuggestion onClick={handleSuggestionClick} />
                        </Box>
                    </div>

                    <div
                        style={{ flex: '1', overflowY: 'auto', display: selectedChatId && allChatMessages[selectedChatId]?.messages?.length ? 'block' : 'none' }}
                        ref={chatContainerRef}
                    >
                        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
                            {(allChatMessages[selectedChatId]?.messages || []).map((msg, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    <ChatMessage owner={msg.role === "user"} message={msg.message} />
                                </div>
                            ))}

                            {isLoading && (
                                <ChatMessageLoader />
                            )}
                            {/* Show the streaming message while loading */}
                            {isResponseStreaming && !isLoading && (
                                <div style={{ marginBottom: '10px' }}>
                                    <Writer
                                        incomingFragments={incomingFragments}
                                        setIncomingFragments={setIncomingFragments}
                                        onComplete={(message) => handleMessageComplete(message)}
                                        scrollChat={ScrollChatBottom}
                                    />
                                </div>
                            )}
                        </Box>
                    </div>

                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const message = e.target.elements.message.value;
                            if (message.trim() !== '') {
                                handleMessageSubmit(message)
                                e.target.reset();
                            }
                        }}
                        style={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: 0, padding: '10px', zIndex: 2, width: '100%', maxWidth: '800px', margin: '0 auto' }}
                    >
                        <Paper sx={{ p: '4px', borderRadius: "16px", display: 'flex', alignItems: 'center', width: '100%' }}>
                            <Tooltip title="previous chat history">
                                <IconButton
                                    onClick={() => setIsDrawerOpen(true)}
                                    sx={{ color: 'white', backgroundColor: '#0D47A1', '&:hover': { backgroundColor: '#1565C0' }, padding: '6px', mr: 1 }}
                                >
                                    <ChatIcon sx={{ fontSize: '20px' }} />
                                </IconButton>
                            </Tooltip>
                            <TextField
                                fullWidth
                                placeholder="Message AiMotor"
                                variant="standard"
                                name="message"
                                multiline
                                InputLabelProps={{
                                    style: { color: 'black', textAlign: 'center', width: '100%' },
                                    shrink: true,
                                }}
                                InputProps={{
                                    style: { color: 'black', textAlign: 'center' },
                                    disableUnderline: true,
                                }}
                                sx={{ textAlign: 'center' }}
                            />
                            <IconButton
                                color="primary"
                                type="submit"
                                style={{ minWidth: 'auto' }}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                        </Paper>
                    </form>

                    {isMobile && (
                        <Chip
                            label={showPersonalButton ? 'Personal Space' : selectedTeam.name}
                            sx={{
                                display: 'block',
                                margin: '0 auto',
                                textAlign: 'center',
                                color: 'black',
                                backgroundColor: '#dee2e6',
                                fontWeight: 'bold',
                                padding: '5px 5px',
                                borderRadius: '22px',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                            }}
                        />
                    )}
                </Box>

                <Drawer
                    onNewChat={handleNewChat}
                    handleChatSelection={handleSelectPreviousChat}
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                />

                <Pricing open={openPricing} onClose={() => setOpenPricing(false)} />
            </Box>
        </>
    )
};

export default Chat;
