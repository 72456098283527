import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from "react-router-dom";
const BackgroundContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #3A49F9 0%, #4D5DFB 100%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30vh',
  color: '#fff',
  textAlign: 'center',
  flexDirection: 'column',
  position: 'relative',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#3A49F9',
  borderRadius: '25px',
  padding: '10px 20px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
}));

const CallToActionSection = () => {
  return (
    <BackgroundContainer>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
       Go Faster today
      </Typography>
      <Link to="/racing-space-try" className="insertdata">
      <StyledButton variant="contained">
Try Free
      </StyledButton>
      </Link>
    </BackgroundContainer>
  );
};

export default CallToActionSection;
