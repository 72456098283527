import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Markdown from 'marked-react';
import Typography from '@mui/material/Typography';

const ChatMessage = ({ owner = true, message = "" }) => {
  return (
    <Grid container textAlign={owner ? "end" : "start"} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="caption" style={{
          textAlign: owner ? 'right' : 'left',
          marginBottom: '5px',
          color: owner ? 'black' : 'black' // Makes text black for AiMotor
        }}>
          {owner ? 'You' : 'AiMotor'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
          p: 2,
          backgroundColor: owner ? '#dee2e6' : '#fff', // Lighter background for user, white for AiMotor
          borderRadius: '16px', // More rounded corners
          whiteSpace: "pre-line",
          color: owner ? 'black' : 'black', // Black text for both
          mb: 1,
          overflow: 'auto',
          display: 'inline-block',
          textAlign: "justify",
          maxWidth: {
            xs: '100%',
            md: '100%'
          },
          "& p:last-child": {
            marginBottom: 0
          }
        }}>
          <Markdown>
            {message}
          </Markdown>
        </Box>
      </Grid>
    </Grid>
  )
};

export default ChatMessage;
