import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { isToday, isWithinInterval, isYesterday, subDays } from 'date-fns';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import TrackMapChat from './TrackMapChat'; // import your MarkOptimization component
import Typography from '@mui/material/Typography';
import axios from 'axios';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1E1E1E',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#AAAAAA',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h6: {
            fontWeight: 'bold',
            color: '#BBBBBB',
        },
    },
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
        backgroundColor: '#333333',
    },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    primary: {
        fontWeight: '500',
        color: '#FFFFFF',
    },
    secondary: {
        color: '#AAAAAA',
    },
}));

const AnchorTemporaryDrawer = ({ onNewChat, handleChatSelection, isOpen, onClose }) => {
    const [historyChats, setHistoryChats] = useState([]);
    const [categorizeChat, setCategorizeChat] = useState({});
    const userData = JSON.parse(localStorage.getItem("user_data"));
    const [race, setRace] = useState("");
    const [day, setDay] = useState("");
    const [driverSessions, setDriverSessions] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false); // State to control Dialog visibility

    const loadHistoryChat = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/get_conversations/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });

        let data = [];
        if (response?.ok) {
            data = await response.json();
        }
        setHistoryChats(data);
        setCategorizeChat(handleCategorizeChats(data));
        return data;
    };

    const getLatestRace = async () => {
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/latest_race`, {
                params: {
                    user_email: userData?.email
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    accept: "application/json",
                },
            })
            .then((res) => {
                setRace(res.data.race);
                setDay(res.data.day);
                setDriverSessions(res.data.driverSessions);
            })
            .catch((error) => {
                console.error("Axios Error:", error);
            });
    };

    useEffect(() => {
        if (isOpen) {
            getLatestRace();
            loadHistoryChat();
        }
    }, [isOpen]);

    const handleCategorizeChats = (chats) => {
        const today = [];
        const yesterday = [];
        const previous7Days = [];
        const previous30Days = [];

        const oneDayAgo = subDays(new Date(), 1);
        const sevenDaysAgo = subDays(new Date(), 7);
        const thirtyDaysAgo = subDays(new Date(), 30);

        chats.forEach(chat => {
            if (isToday(new Date(chat.last_interaction))) {
                today.push(chat);
            } else if (isYesterday(new Date(chat.last_interaction))) {
                yesterday.push(chat);
            } else if (isWithinInterval(new Date(chat.last_interaction), { start: sevenDaysAgo, end: oneDayAgo })) {
                previous7Days.push(chat);
            } else if (isWithinInterval(new Date(chat.last_interaction), { start: thirtyDaysAgo, end: oneDayAgo })) {
                previous30Days.push(chat);
            }
        });

        return { today, yesterday, previous7Days, previous30Days };
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <>
        <ThemeProvider theme={darkTheme}>
            <Drawer anchor="right" open={isOpen} onClose={onClose}>
                <Box sx={{ width: 280, backgroundColor: '#1E1E1E', color: '#FFFFFF' }}>
                    <List>
                        <StyledListItem disablePadding button onClick={handleOpenDialog}>
                            <IconButton>
                                <MyLocationIcon />
                            </IconButton>
                            <StyledListItemText primary="Track Map" />
                        </StyledListItem>
                        <StyledListItem disablePadding button onClick={onNewChat}>
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            <StyledListItemText primary="New Chat" />
                        </StyledListItem>

                    </List>
                    <Divider sx={{ backgroundColor: '#333333' }} />

                    {categorizeChat?.today?.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ padding: 2 }}>Today's Chats</Typography>
                            <List>
                                {categorizeChat.today.map(chat => (
                                    <StyledListItem key={chat._id} disablePadding button onClick={() => handleChatSelection(chat._id)}>
                                        <StyledListItemText primary={chat?.messages?.[0]?.message || "no messages"} />
                                    </StyledListItem>
                                ))}
                            </List>
                            <Divider sx={{ backgroundColor: '#333333' }} />
                        </>
                    )}
                    {categorizeChat?.yesterday?.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ padding: 2 }}>Yesterday's Chats</Typography>
                            <List>
                                {categorizeChat.yesterday.map(chat => (
                                    <StyledListItem key={chat._id} disablePadding button onClick={() => handleChatSelection(chat._id)}>
                                        <StyledListItemText primary={chat?.messages?.[0]?.message || "no messages"} />
                                    </StyledListItem>
                                ))}
                            </List>
                            <Divider sx={{ backgroundColor: '#333333' }} />
                        </>
                    )}
                    {categorizeChat?.previous7Days?.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ padding: 2 }}>Previous 7 days Chats</Typography>
                            <List>
                                {categorizeChat.previous7Days.map(chat => (
                                    <StyledListItem key={chat._id} disablePadding button onClick={() => handleChatSelection(chat._id)}>
                                        <StyledListItemText primary={chat?.messages?.[0]?.message || "no messages"} />
                                    </StyledListItem>
                                ))}
                            </List>
                            <Divider sx={{ backgroundColor: '#333333' }} />
                        </>
                    )}
                    {categorizeChat?.previous30Days?.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ padding: 2 }}>Previous 30 days Chats</Typography>
                            <List>
                                {categorizeChat.previous30Days.map(chat => (
                                    <StyledListItem key={chat._id} disablePadding button onClick={() => handleChatSelection(chat._id)}>
                                        <StyledListItemText primary={chat?.messages?.[0]?.message || "no messages"} />
                                    </StyledListItem>
                                ))}
                            </List>
                            <Divider sx={{ backgroundColor: '#333333' }} />
                        </>
                    )}
                    {categorizeChat?.today?.length === 0 && (
                        <Typography variant="h6" sx={{ padding: 2, textAlign: 'center' }}>No Chats Available</Typography>
                    )}

                </Box>
            </Drawer>
        </ThemeProvider>
            <Dialog open={isDialogOpen} onClose={handleCloseDialog}  fullWidth>
                <DialogContent>
                    <IconButton
                        onClick={handleCloseDialog}
                        style={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <TrackMapChat
                        race={race}
                        driverSessions={[driverSessions]}
                        day={day}
                    />
                </DialogContent>
            </Dialog>
        </>
       
    );
};

export default AnchorTemporaryDrawer;