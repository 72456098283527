import React, { useState } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify'; // Assuming you are using react-toastify for notifications

const ContactPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { firstName, lastName, email, message } = formData;
    const accessToken = localStorage.getItem("access_token");

    const queryParams = new URLSearchParams({
      name: firstName,
      surname: lastName,
      email: email,
      message: message,
    }).toString();

    const url = `${process.env.REACT_APP_API_DOMAIN}/contact_message/?${queryParams}`;

    console.log("Request URL:", url);
    console.log("Headers:", {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      accept: "application/json",
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          accept: "application/json",
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Response Data:", responseData);
        toast.success("Message sent successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          message: '',
        });
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      toast.error("Failed to send the message!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
      });
      console.error("Query Error:", error);
    }
  };

  const pageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#121212',
    color: '#ffffff',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  };

  const contentContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    maxWidth: '1000px',
  };

  const formContainerStyle = {
    backgroundColor: '#1e1e1e',
    padding: '40px',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '600px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const formGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
  };

  const halfWidthInputStyle = {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #333',
    backgroundColor: '#2e2e2e',
    color: '#ffffff',
    marginBottom: '10px',
  };

  const fullWidthInputStyle = {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #333',
    backgroundColor: '#2e2e2e',
    color: '#ffffff',
    marginBottom: '10px',
  };

  const textareaStyle = {
    ...fullWidthInputStyle,
    resize: 'vertical',
    height: '100px',
  };

  const buttonStyle = {
    width: '100%',
    padding: '10px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#3b3b3b',
    color: '#ffffff',
    cursor: 'pointer',
  };

  const contactInfoContainerStyle = {
    marginTop: '20px',
    width: '100%',
    maxWidth: '600px',
    textAlign: 'center',
  };

  const contactInfoStyle = {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  };

  const contactItemStyle = {
    marginBottom: '10px',
  };

  // Responsive styles
  const mediaQueryStyles = `
    @media (min-width: 768px) {
      .content-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      .contact-info-container {
        margin-top: 0;
        margin-left: 20px;
        text-align: left;
      }
    }
  `;

  return (
    <div style={pageStyle}>
      <style>{mediaQueryStyles}</style>
      <div className="content-container" style={contentContainerStyle}>
        <div style={formContainerStyle}>
          <h2>Contact us</h2>
          <form onSubmit={handleSubmit}>
            <div style={{ ...formGroupStyle, flexDirection: 'row', justifyContent: 'space-between' }}>
              <input
                style={{ ...halfWidthInputStyle, width: '48%' }}
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                style={{ ...halfWidthInputStyle, width: '48%' }}
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div style={formGroupStyle}>
              <input
                style={fullWidthInputStyle}
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div style={formGroupStyle}>
              <textarea
                style={textareaStyle}
                id="message"
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <button style={buttonStyle} type="submit">Send</button>
          </form>
        </div>
        <div className="contact-info-container" style={contactInfoContainerStyle}>
          <ul style={contactInfoStyle}>
            <li style={contactItemStyle}><strong>Support:</strong> support@aimotor.eu</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
