import { Skeleton, Stack } from '@mui/material';

const ChatMessageLoader = () => {
  return (
    <Stack direction="column" spacing={1} alignItems={'start'}>
      <Skeleton animation="wave" variant="rectangular" width={90} height={20} sx={{
        borderRadius: '8px'
      }} />
      <Skeleton
        variant='h1'
        animation="wave"
        height={80}
        width="50%"
        sx={{
          borderRadius: '8px'
        }}
      />
    </Stack>
  )
};

export default ChatMessageLoader;
