import { Box, Chip, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { LineChart } from '@mui/x-charts/LineChart';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import axios from 'axios';

export default function MarkOptimization({ track, hoverLatitude, hoverLongitude, trackIndex, race, driverSessions, day }) {
  const [chartSize, setChartSize] = useState({ width: window.innerWidth < 500 ? 350 : 500, height: window.innerWidth < 500 ? 210 : 300 });
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [showSectors, setShowSectors] = useState(true);
  const [showSectorNumbers, setShowSectorNumbers] = useState(false);
  const [ballColors, setBallColors] = useState([]);
  const [sectorParts, setSectorParts] = useState([]);  // Initialization added here
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  let paddingBlock = 49.2;
  let paddingInline = 49.2;
  console.log("padding", paddingBlock)


  const getSectors = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/get_change_in_sector?race=${race}&&day=${day}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            'Content-Type': 'application/json',
          },
        }
      )
      .then((userResponse) => {
        const sectorTimes = userResponse.data[0]?.sectorData?.sector_times || [];
        const roundedSectorParts = sectorTimes.map(time => Math.round(time / 0.16));
        setSectorParts(roundedSectorParts); // Use setSectorParts to update state
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  };

  const getTrackData = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/get_filtered_longitude_latitude?race=${race}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            'Content-Type': 'application/json',
          },
        }
      )
      .then((userResponse) => {
        setIsLoading(false);
        setDataset(userResponse.data);
        getSectors(); // Retrieve sector parts after getting track data
      })
      .catch((error) => {
        console.error("Query Error:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTrackData();
  }, [, chartSize, driverSessions, track]);

  useEffect(() => {
    const generateRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const colors = dataset.map(() => generateRandomColor());
    setBallColors(colors);
  }, [dataset]);

  const increaseSize = () => {
    if (chartSize.width < window.innerWidth) {
      setChartSize((prevSize) => ({
        width: prevSize.width * 1.1,
        height: prevSize.height * 1.1,
      }));
      paddingBlock = paddingBlock * 1.1;
      paddingInline = paddingInline * 1.1;
    }
  };

  const decreaseSize = () => {
    setChartSize((prevSize) => ({
      width: Math.max(prevSize.width * 0.9, 100),
      height: Math.max(prevSize.height * 0.9, 100),
    }));
  };

  const handleTouchOrClickOutside = (event) => {
    if (chartRef.current && !chartRef.current.contains(event.target)) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchOrClickOutside, { passive: true });
    document.addEventListener('mousedown', handleTouchOrClickOutside);

    return () => {
      document.removeEventListener('touchstart', handleTouchOrClickOutside);
      document.removeEventListener('mousedown', handleTouchOrClickOutside);
    };
  }, []);

  const calculateTopPosition = (latitude) => {
    const minLatitude = Math.min(...latitudeData);
    const maxLatitude = Math.max(...latitudeData);
    const mapHeight = chartSize.height - paddingBlock * 2;
    return ((maxLatitude - latitude) * mapHeight) / (maxLatitude - minLatitude);
  };

  const calculateLeftPosition = (longitude) => {
    const minLongitude = Math.min(...longitudeData);
    const maxLongitude = Math.max(...longitudeData);
    const mapWidth = chartSize.width - paddingBlock * 2;
    return ((longitude - minLongitude) / (maxLongitude - minLongitude)) * mapWidth;
  };

  const longitudeData = dataset[0]?.drivingData?.map((point) => point.longitude) || [];
  const latitudeData = dataset[0]?.drivingData?.map((point) => point.latitude) || [];

  const marginTopValue = chartRef.current ? -chartRef.current.clientHeight : -chartSize.height;

  const minLongitude = Math.min(...longitudeData);
  const maxLongitude = Math.max(...longitudeData);
  const minLatitude = Math.min(...latitudeData);
  const maxLatitude = Math.max(...latitudeData);

  const series = showSectors ? sectorParts?.map((start, index) => {
    const end = sectorParts[index + 1] !== undefined ? sectorParts[index + 1] : latitudeData?.length;
    if (start >= end || start < 0 || end > latitudeData.length) {
      return null;
    }
    return {
      data: [
        ...(index !== 0 ? Array(start).fill(null) : []),
        ...latitudeData.slice(start, end + 1)
      ],
      showMark: false,
      lineStyle: { stroke: 'transparent' },
      id: `sector-${index}`,
    };
  }).filter(Boolean) : [{
    data: latitudeData,
    showMark: false,
    lineStyle: { stroke: 'transparent' },
    id: 'track'
  }];

  return (
    <>
    
      <Draggable bounds={'body'}>
        <Box
          sx={{ position: 'relative', display: 'inline-block' }}
          onMouseEnter={() => setIsHovered(true)}
          onTouchStart={() => setIsHovered(true)}
          ref={chartRef}
        >
          {isHovered && (
            <>
              <Chip
                label="Show Sectors"
                color={showSectors ? 'primary' : 'default'}
                onClick={() => setShowSectors(!showSectors)}
                onTouchStart={() => setShowSectors(!showSectors)}
                sx={{ position: 'absolute', top: 10, left: 10, zIndex: 12 }}
              />
              {showSectors && (
                <Chip
                  label="Sector Numbers"
                  color={showSectorNumbers ? 'secondary' : 'default'}
                  onClick={() => setShowSectorNumbers(!showSectorNumbers)}
                  onTouchStart={() => setShowSectorNumbers(!showSectorNumbers)}
                  sx={{ position: 'absolute', top: 10, left: 130, zIndex: 12, fontSize: '12px' }}
                />
              )}
              {/* Legend Box */}
              <Paper
                sx={{
                  position: 'absolute',
                  top:  chartSize.width > window.innerWidth* 0.8 ? chartSize.height * 0.45 : chartSize.height * 0.8,
                  right: chartSize.width > window.innerWidth* 0.8 ? -chartSize.width / 3 : +chartSize.width / 3,
                  zIndex: 12,
                  padding: '10px',
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                }}
              >
                {dataset.map((data, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: ballColors[index],
                        borderRadius: '50%',
                        marginRight: '8px',
                      }}
                    />
                    {/* Update to use surname instead of email */}
                    <Typography variant="body2">{data.surname || 'Unknown'}</Typography>
                    <Typography variant="body2" sx={{ marginLeft: 'auto' }}>Lap: {data.lap || 'N/A'}</Typography>
                  </Box>
                ))}
              </Paper>
            </>
          )}
          {!isLoading && dataset[0]?.drivingData?.length > 0 ? (
            <>
              <LineChart
                axisHighlight={false} // Disable axis highlighting
                grid={false} // Disable grid lines
                xAxis={[{
                  data: longitudeData,
                  min: minLongitude,
                  max: maxLongitude,
                  hide: true, // Hide x-axis
                  disableLine: true,
                  disableTicks: true,
                  hideTooltip: true,
                  tickInterval: []
                }]}
                yAxis={[{
                  min: minLatitude,
                  max: maxLatitude,
                  hide: true, // Hide y-axis
                  disableLine: true,
                  disableTicks: true,
                  hideTooltip: true,
                  tickInterval: []
                }]}
                series={series}
                width={chartSize.width}
                height={chartSize.height}
                highlightedItem={{
                  seriesId: 'track',
                  dataIndex: 10,
                }}
                interactions={{ hover: true, tooltip: { enabled: true } }}
              />
              {dataset.map((data, index) => {
                const currentLatitude = data?.drivingData?.[trackIndex]?.latitude;
                const currentLongitude = data?.drivingData?.[trackIndex]?.longitude;
                const ballColor = ballColors[index];

                return (
                  currentLatitude && currentLongitude && (
                    <div
                      key={index}
                      ref={containerRef}
                      style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        marginTop: `${marginTopValue}px`,
                        paddingBlock: `${paddingBlock}px`,
                        paddingInline: `${paddingInline}px`,
                      }}
                    >
                      <div
                        style={{
                          position: 'relative',
                          top: `${calculateTopPosition(currentLatitude)}px`,
                          left: `${calculateLeftPosition(currentLongitude)}px`,
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: ballColor,
                          borderRadius: '50%',
                          width: '10px',
                          height: '10px',
                          zIndex: 11,
                        }}
                      />
                    </div>
                  )
                );
              })}

              {showSectors && sectorParts?.map((point, index) => (
                <div
                  key={index}
                  ref={containerRef}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    marginTop: `${marginTopValue}px`,
                    paddingBlock: `${paddingBlock}px`,
                    paddingInline: `${paddingInline}px`,
                  }}
                >
                   {showSectorNumbers ? (
                    // Render the sector numbers instead of X markers
                    <Typography
                      variant="caption"
                      style={{
                        position: 'relative',
                        top: `${calculateTopPosition(dataset[0]?.drivingData[point]?.latitude)-8}px`,
                        left: `${calculateLeftPosition(dataset[0]?.drivingData[point]?.longitude)-3}px`,
                        transform: 'translate(-50%, -50%)',
                        zIndex: 12,
                        color: 'black',
                        fontSize: window.innerWidth < 400 ? '12px' : '14px',// Adjust font size as needed
                        padding: '2px',
                        borderRadius: '4px',
                        // Optional: Add background color for better visibility
                      }}
                    >
                      {index == 0 ? sectorParts?.length-1: index-1} {/* Display the sector number */}
                    </Typography>
                  ) : (
                    // Render the original X markers when sector numbers are not selected
                    <Tooltip title={`Sector ${index + 1}`}>
                      <CloseIcon
                        style={{
                          position: 'relative',
                          top: `${calculateTopPosition(dataset[0]?.drivingData[point]?.latitude)}px`,
                          left: `${calculateLeftPosition(dataset[0]?.drivingData[point]?.longitude)}px`,
                          transform: 'translate(-50%, -50%)',
                          color: 'black',
                          fontSize: '20px',
                          zIndex: 10,
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              ))}
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: chartSize.width,
                height: chartSize.height,
                backgroundColor: '#f0f0f0',
                borderRadius: '8px',
              }}
            >
              {isLoading ? 'Loading data...' : (!race ? 'Select Race' : (driverSessions ? 'Select a lap' : 'Select a Driver'))}

            </Box>
          )}
          {isHovered && (
            <Box
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '4px',
                padding: '4px',
              }}
            >
              {chartSize.width < window.innerWidth && (<IconButton onClick={increaseSize} size="small" sx={{ zIndex: 300 }}
                onTouchStart={increaseSize}
              >
                <ZoomInIcon />
              </IconButton>)}
              <IconButton onClick={decreaseSize} size="small" sx={{ zIndex: 300 }}
                onTouchStart={decreaseSize}
              >
                <ZoomOutIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Draggable>
    </>
  );
}
