import { Alert, Box, Button, Card, CardContent, FormControl, Hidden, IconButton, MenuItem, Modal, Select, Skeleton, Typography } from '@mui/material';
import { Grid, InputLabel } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import axios from 'axios';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite";
import { styled } from '@mui/system';
import { useDropzone } from 'react-dropzone';

function LinearWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </Box>
  );
}

const FileIconButton = styled(IconButton)(({ theme, showError, isComplete }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '150px',
  height: '100px',
  margin: theme.spacing(2),
  border: `2px dashed ${showError ? theme.palette.error.main : isComplete ? theme.palette.success.main : theme.palette.error.main}`,
  borderRadius: 0,
}));

const FileCard = ({ file, onClose, onSave, onDelete, error, competitions, competitionLoading, setCompetitionLoading, race, showPersonalButton, setRace, handleChange, driver, lastSelectedDriver, emailName, drivers, setDriver }) => {
  const [sessionNumber, setSessionNumber] = useState(file?.sessionNumber || '');
  const [selectedSessionNumber, setSelectedSessionNumber] = useState(file?.sessionNumber || '');
  const [competition, setCompetition] = useState(file?.competition || '');
  const [selectOpen, setSelectOpen] = useState(false);

  const handleSave = () => {
    onSave({ ...file, sessionNumber, race, driver });

  };

  const handleSessionClick = (sessionNumber) => {
    setSessionNumber(sessionNumber);
    setSelectedSessionNumber(sessionNumber);
  };

  const handleDoneClick = (event) => {
    event.stopPropagation();
    setSelectOpen(false);
  };

  const selectedName = emailName.find((dataItem) => dataItem.email === driver);
  const name = selectedName ? selectedName.surname : '';

  return (
    <Modal open={Boolean(file)} onClose={onClose}>
      <Card sx={{ maxWidth: 400, mx: 'auto', mt: '10%', p: 2 }}>
        <CardContent>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
            <div>
              <Typography variant="h6">
                <strong>File name:</strong> {/* Display file name here */}
              </Typography>
              <Typography variant="h6">
                {file.file.name}
              </Typography>
            </div>


            <IconButton onClick={onDelete} color="error" sx={{ marginTop: -4 }}>
              <DeleteIcon />
            </IconButton>
          </div>
          <Box sx={{ display: 'flex', flexDirection: "column", gap: 3 }}>
            <Grid>
              <FormControl fullWidth sx={{ textAlign: 'left', background: 'rgba(0, 0, 0, 0.00)' }}>
                <InputLabel id="race-select-label">RACE</InputLabel>
                <Select
                  labelId="race-select-label"
                  id="race-select"
                  value={race}
                  onChange={(e) => {
                    setRace(e.target.value);
                  }}
                  label="Race"
                >
                  {!competitionLoading ? (
                    competitions?.length === 0 ? (
                      <MenuItem value="">Please Insert a Competition</MenuItem>
                    ) : [
                      <MenuItem key="select-race" value="">Select Race</MenuItem>,
                      ...competitions?.map((competition) => (
                        <MenuItem key={competition.id} value={competition.id}>
                          {competition.competition} ({competition.track})
                        </MenuItem>
                      ))
                    ]
                  ) : (
                    <MenuItem value="" disabled>
                      <Skeleton variant="rectangular" sx={{ borderRadius: '8px', marginLeft: 0.5 }} height={30} />
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {!showPersonalButton && (
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <FormControl sx={{}} style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-chip-label">Driver</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    value={driver}
                    label="driver "
                    onChange={handleChange}
                    open={selectOpen}
                    onClose={() => setSelectOpen(false)}
                    onOpen={() => setSelectOpen(true)}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={name} label={name} />
                      </Box>
                    )}
                  >
                    {drivers.map((driver) => (
                      <MenuItem key={driver.id} value={driver.email}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          {driver.surname}
                          {lastSelectedDriver === driver.email && (
                            <Chip
                              label="done"
                              color="success"
                              size="small"
                              onClick={handleDoneClick}
                            />
                          )}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body1" color="black" sx={{ fontSize: '18px', fontWeight: 'lighter' }}>
                Session Number:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {[1, 2, 3, 4, 5, 6].map((sessionNumber) => (
                  <Chip onClick={() => handleSessionClick(sessionNumber)} key={sessionNumber} label={sessionNumber} color="default" size="medium" variant={sessionNumber === selectedSessionNumber ? "contained" : "outlined"} />
                ))}
              </Box>
            </Grid>
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                Please insert all the file info
              </Alert>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginTop: 5 }}>
            <Button variant="outlined" onClick={onClose}>Cancel</Button>
            <Button variant="contained" onClick={handleSave}>Save</Button>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};



const FileUpload = () => {
  const [fileGroups, setFileGroups] = useState([]); // Initialize as an array of arrays
  const [selectedFileIndex, setSelectedFileIndex] = useState({ group: -1, file: -1 });
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [competitionLoading, setCompetitionLoading] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [race, setRace] = useState("");
  const [lastSelectedDriver, setLastSelectedDriver] = useState(null);
  const [emailName, setEmailName] = useState([]);
  const [driver, setDriver] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditingDirectly, setIsEditingDirectly] = useState(false); // New state to track direct editing
  const [progressVisible, setProgressVisible] = useState(false); // New state for progress bar visibility
  const selectedTeam = myUserStore.getSelectedTeam;
  const isDriver = selectedTeam?.role === "DRV";
  const showPersonalButton = myUserStore.isPersonalSpace;
  const [uploadStatus, setUploadStatus] = useState({});
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
  const myEmail = parsedUserData?.email;

  useEffect(() => {
    setCompetitionLoading(true);
    const fetchCompetitions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`);
        const data = response.data;
        setCompetitions(data);
        setCompetitionLoading(false);

        if (data.length > 0) {
          setRace(data[data.length - 1].id);
        }
      } catch (error) {
        setCompetitionLoading(false);
        console.error("Error fetching competitions:", error);
      }
    };

    fetchCompetitions();
  }, [selectedTeam]);

  const handleChange = (event) => {
    const selectedDrivers = event.target.value;
    const { value } = event.target;
    if (value.length > 0) {
      setLastSelectedDriver(value[value.length - 1]);
    } else {
      setLastSelectedDriver(null);
    }
    setDriver(selectedDrivers);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        if (showPersonalButton) {
          setDrivers(res.data);
          setDriver([res.data[0].email]);
        } else {
          setDrivers(res.data);
          if (isDriver) {
            const myDriver = res.data.find(item => item.email === myEmail);
            setDriver([myDriver?.email]);
          }
        }
        setEmailName(res.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [selectedTeam]);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    const csvFiles = acceptedFiles.filter(file => file.type === 'text/csv');
    const nonCsvFiles = rejectedFiles.length > 0;

    if (nonCsvFiles) {
      setErrorMessage('Incorrect file format. Please upload a CSV file.');
    } else {
      const newFiles = csvFiles.map(file => ({ file, sessionNumber: '', race: '', driver: '' }));
      if (newFiles.length > 0) {
        setFileGroups((prevGroups) => [...prevGroups, newFiles]); // Add new group as an array
        setSelectedFileIndex({ group: fileGroups.length, file: 0 }); // Open the first file of the new group
        setRace(''); // Reset race
        setDriver([]); // Reset driver
        setLastSelectedDriver(null); // Reset last selected driver
      }
      setErrorMessage('');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv']
    }
  });

  const handleModalClose = () => {
    setSelectedFileIndex({ group: -1, file: -1 }); // Close modal by setting index to -1
    setIsEditingDirectly(false); // Reset direct editing state
  };

  const handleFileClick = (groupIndex, fileIndex) => {
    setSelectedFileIndex({ group: groupIndex, file: fileIndex }); // Open the selected file card
    setIsEditingDirectly(true); // Set direct editing mode
    // Set values from the selected file
    const file = fileGroups[groupIndex][fileIndex];
    setRace(file.race || '');
    setDriver(file.driver || []);
    setLastSelectedDriver(file.driver?.length ? file.driver[file.driver.length - 1] : null);
  };

  const handleFileSave = (fileDetails) => {
    const { group, file } = selectedFileIndex;
    const updatedFiles = fileGroups.map((groupFiles, groupIndex) =>
      groupIndex === group
        ? groupFiles.map((f, fileIndex) =>
          fileIndex === file ? { ...fileDetails, file: new File([f.file], fileDetails.file.name) } : f
        )
        : groupFiles
    );
    setFileGroups(updatedFiles);

    if (isEditingDirectly) {
      // If editing directly, don't change the file index
      handleModalClose();
    } else {
      // Sequential processing
      if (file < fileGroups[group].length - 1) {
        // Move to the next file in the group
        setSelectedFileIndex({ group, file: file + 1 });
        setRace(''); // Reset race
        setDriver([]); // Reset driver
        setLastSelectedDriver(null); // Reset last selected driver
      } else {
        setSelectedFileIndex({ group: -1, file: -1 }); // Close if it's the last file
      }
    }
  };

  const handleFileDelete = () => {
    const { group, file } = selectedFileIndex;
    const updatedFiles = fileGroups.map((groupFiles, groupIndex) =>
      groupIndex === group ? groupFiles.filter((_, fileIndex) => fileIndex !== file) : groupFiles
    );
    setFileGroups(updatedFiles);
    setSelectedFileIndex({ group: -1, file: -1 }); // Close the modal after deletion
    setIsEditingDirectly(false); // Reset direct editing state
  };

  const handleSubmit = async () => {
    debugger
    const incompleteFiles = fileGroups.flat().filter(file => !file?.sessionNumber || !file.race || !file.driver);
    if (incompleteFiles.length > 0) {
      setShowError(true);
      const firstIncompleteIndex = fileGroups.flat().indexOf(incompleteFiles[0]);
      setSelectedFileIndex({
        group: Math.floor(firstIncompleteIndex / fileGroups[0].length),
        file: firstIncompleteIndex % fileGroups[0].length,
      });
      return;
    }

    setShowError(false);
    setIsSubmitting(true);
    setSuccessMessage('');
    setProgressVisible(true); // Show progress bar

    try {
      const token = localStorage.getItem('access_token');

      for (const group of fileGroups) {
        for (const file of group) {
          const formData = new FormData();
          formData.append('file', file.file);

          const queryParams = new URLSearchParams();
          queryParams.append('race', file.race);
          queryParams.append('session', file?.sessionNumber);
          queryParams.append('email', file.driver);
          queryParams.append('team_id', selectedTeam.id);

          const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/upload/?${queryParams.toString()}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
              'accept': 'application/json',
            },
          });

          if (response.status === 200) {
            setUploadStatus((prevStatus) => ({
              ...prevStatus,
              [file.file.name]: 'success', // Aggiorna lo stato a 'success' per il file corrente
            }));
          }
        }
      }

      setSuccessMessage('Files uploaded successfully!');
      setFileGroups([]);
    } catch (error) {
      console.error('Error uploading files', error);
      alert('Error uploading files');
    } finally {
      setIsSubmitting(false);
      setProgressVisible(false); // Hide progress bar
    }
  };

  const isFileComplete = (file) => file?.sessionNumber && file.race && file.driver;

  const performQuery = (e) => {
    e.preventDefault();
  };
  console.log(selectedFileIndex)

  return (
    <Box>

      <Box {...getRootProps()} sx={{ border: '2px dashed', padding: 2, textAlign: 'center', cursor: 'pointer', height: 350, alignContent: 'center', flexDirection: 'column', alignItems: 'center', paddingTop: '50px' }}>
        <input {...getInputProps()} />
        <Typography>Drag and drop one or more files here, or click to select the files</Typography>
        <Button variant="contained" component="span">Select Files</Button>
      </Box>
      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
        {fileGroups.map((group, groupIndex) =>
          Array.isArray(group) && group.map((file, fileIndex) => (
            <FileIconButton
              key={`${groupIndex}-${fileIndex}`}
              onClick={() => handleFileClick(groupIndex, fileIndex)}
              showError={!isFileComplete(file)}
              isComplete={isFileComplete(file)}
            >
              <UploadFileIcon fontSize="large" />
              <Typography>{file.file.name}</Typography>
              {uploadStatus[file.file.name] === 'success' && (
                <CheckCircleIcon sx={{ color: 'green', position: 'absolute', top: 5, right: 5 }} />
              )}
            </FileIconButton>
          ))
        )}
      </Box>
      {selectedFileIndex.group >= 0 && selectedFileIndex.file >= 0 && (
        <FileCard
          file={fileGroups[selectedFileIndex.group][selectedFileIndex.file]}
          onClose={handleModalClose}
          onSave={handleFileSave}
          onDelete={handleFileDelete}
          error={showError && !isFileComplete(fileGroups[selectedFileIndex.group][selectedFileIndex.file])}
          competitions={competitions}
          competitionLoading={competitionLoading}
          setCompetitionLoading={setCompetitionLoading}
          race={race}
          setRace={setRace}
          handleChange={handleChange}
          driver={driver}
          lastSelectedDriver={lastSelectedDriver}
          emailName={emailName}
          drivers={drivers}
          setDriver={setDriver}
          showPersonalButton={showPersonalButton}
        />
      )}
      {fileGroups.flat().length > 0 && (
        !isSubmitting ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 5 }}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        ) : (
          <LinearWithValueLabel />
        )
      )}
      {successMessage && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {successMessage}
        </Alert>
      )}
    </Box>
  );
};

export default observer(FileUpload);
