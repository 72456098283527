import * as React from 'react';

import { FormControl, Grid, IconButton, InputLabel, Select } from '@mui/material';
import { useEffect, useRef, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import EditableCell from "../EditableCell";
import EditableCellNumeric from "../EditableCellNumeric";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuItem from '@mui/material/MenuItem';
import Mmenu from '@mui/material/Menu'
import { Modal } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import RemoveIcon from '@mui/icons-material/Remove';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from "axios";
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { toast } from "react-toastify";

const PageHeader = ({ drivers, onChange = () => { }, race, day, competitions, onDayChange = () => { }, onRaceChange = () => { }, competitionLoading }) => {
  const [driver, setDriver] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastSelectedDriver, setLastSelectedDriver] = useState(null);
  const [selectOpen, setSelectOpen] = useState(false);

  const handleChange = (event) => {
    const selectedDrivers = event.target.value;
    const { value } = event.target;
    onChange(selectedDrivers);
    setDriver(selectedDrivers);
    if (value.length > 0) {
      setLastSelectedDriver(value[value.length - 1]);
    } else {
      setLastSelectedDriver(null);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDay = (day) => {
    onDayChange(day);
    handleMenuClose();
  };

  const handleDoneClick = (event) => {
    event.stopPropagation();
    setSelectOpen(false);
  };

  const showPersonalButton = myUserStore.isPersonalSpace;

  const racingDays = [
    { name: 'First Racing Day', value: 1 },
    { name: 'Second Racing Day', value: 2 },
    { name: 'Third Racing Day', value: 3 },
    { name: 'Fourth Racing Day', value: 4 },
    { name: 'Fifth Racing Day', value: 5 },
    { name: 'Sixth Racing Day', value: 6 },
    { name: 'Seventh Racing Day', value: 7 },
  ];

  return (
    <div>
      <Grid container columnSpacing={3} sx={{ alignItems: "center" }}>
        <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
          <Typography sx={{ fontSize: 40 }}>Archive</Typography>
        </Grid>
        <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
          <div className="frame1" style={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: '10px', width: '100%' }}>
            <FormControl fullWidth sx={{ textAlign: 'left', background: 'rgba(0, 0, 0, 0.00)' }}>
              <InputLabel id="race-select-label">RACE</InputLabel>
              <Select
                labelId="race-select-label"
                id="race-select"
                value={race}
                onChange={(e) => {
                  onRaceChange(e.target.value);
                  setDriver([]);
                }}
                label="Race"
              >
                {!competitionLoading ? (
                  competitions.length === 0 ? (
                    <MenuItem value="">Please Insert a Competition</MenuItem>
                  ) : (
                    [
                      <MenuItem key="select-race" value="">Select Race</MenuItem>,
                      ...competitions.map((competition) => (
                        <MenuItem key={competition.id} value={competition.id}>
                          {competition.competition} ({competition.track})
                        </MenuItem>
                      )),
                    ]
                  )
                ) : (
                  <MenuItem value="" disabled>
                    <Skeleton variant="rectangular" sx={{ borderRadius: '8px', marginLeft: 0.5 }} height={30} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <Button
              className="button"
              aria-controls="number-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              style={{ whiteSpace: 'nowrap', color: !day ? 'red' : 'green' }}
            >
              <CalendarMonthIcon className="calendar-month" />
            </Button>
            <Mmenu
              id="number-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {racingDays.map((racingDay) => (
                <MenuItem key={racingDay.value} onClick={() => handleSelectDay(racingDay.value)}>
                  {racingDay.name}
                </MenuItem>
              ))}
            </Mmenu>
          </div>
        </Grid>
        {!showPersonalButton && (
          <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
            <div className="autocomplete-wrapper" style={{ width: "100%" }}>
              <div className="autocomplete" style={{ width: '100%' }}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-chip-label">Driver</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={driver}
                    label="Driver"
                    onChange={handleChange}
                    open={selectOpen}
                    onClose={() => setSelectOpen(false)}
                    onOpen={() => setSelectOpen(true)}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedName = drivers.find((dataItem) => dataItem.email === value);
                          const name = selectedName ? selectedName.surname : '';
                          return <Chip key={value} label={name} />;
                        })}
                      </Box>
                    )}
                  >
                    {drivers.length === 0 ? (
                      <MenuItem key="no-driver" value="" disabled>
                        No driver has inserted yet!
                      </MenuItem>
                    ) : (
                      drivers.map((driver) => (
                        <MenuItem key={driver.id} value={driver.email}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            {driver.surname}
                            {lastSelectedDriver === driver.email && (
                              <Chip label="done" color="success" size="small" onClick={handleDoneClick} />
                            )}
                          </Box>
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};



function Row(props) {
  const { rows, onDelete, updateRows } = props;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1);
  const [show, setShow] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);
  const selectedTeam = myUserStore.getSelectedTeam;
  const tableContainerRef = useRef(null);

  const updateValue = (value, field, id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/change_data/?value=${value}&field=${field}&id=${id}&team_id=${selectedTeam.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            accept: 'application/json',
          },
        }
      )
      .then(() => {
        toast.success('Value updated successfully!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
        });

        const updatedRows = rows.map((row) => (row.id === id ? { ...row, [field]: value } : row));
        updateRows(updatedRows);
      })
      .catch((error) => {
        toast.error('Failed to update the value!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.error('Query Error:', error);
      });
  };

  const deleteValue = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/delete_data/?id=${id}&team_id=${selectedTeam.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            accept: 'application/json',
          },
        }
      )
      .then(() => {
        toast.success('Entry deleted successfully!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
        });
        handleClose();
        onDelete(id);
      })
      .catch((error) => {
        toast.error('Failed to delete the entry!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.error('Query Error:', error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const handleShowAllFieldsClick = (e) => {
    e.stopPropagation();
    setShowAllFields((prev) => !prev);
  };

  useEffect(() => {
    if (showAllFields) {
      // Ensure the table scrolls to the right after showing more fields
      setTimeout(() => {
        if (tableContainerRef.current) {
          tableContainerRef.current.scrollTo({
            left: tableContainerRef.current.scrollWidth,
            behavior: 'smooth', // Smooth scrolling for better UX
          });
        }
      }, 200); // Slightly longer delay to ensure DOM is updated
    }
  }, [showAllFields]); // Trigger useEffect when showAllFields changes

  // Mapping functions
  const mapperYN = (value) => (value === 1 ? 'Yes' : 'No');
  const axlePositionMapping = { 0: 'low', 1: 'medium', 2: 'high' };
  const reverseAxlePositionMapping = { low: 0, medium: 1, high: 2 };

  const isDriver = selectedTeam.role === 'DRV';
  const parsedUserData = JSON.parse(localStorage.getItem('user_data'));
  const mySurname = parsedUserData?.surname;
  const canModify = !isDriver || mySurname === rows[0].surname;

  const defaultFields = [
    { label: 'Session Number', field: 'session_number', component: EditableCellNumeric },
    { label: 'Lap Time', field: 'laptime', component: EditableCell },
    { label: 'Chassis Number', field: 'chassis_number', component: EditableCell },
    { label: 'Engine Number', field: 'engine_number', component: EditableCell },
    { label: 'Tires New', field: 'tires_new', component: EditableCell },
    { label: 'Hot Pressure', field: 'pressure_hot', component: EditableCellNumeric },
    { label: 'Cold Pressure', field: 'pressure_cold', component: EditableCellNumeric },
    { label: 'Sprocket', field: 'crown', component: EditableCellNumeric },
    { label: 'Pinion', field: 'pinion', component: EditableCellNumeric },
    { label: 'Min RPM', field: 'min_rpm', component: EditableCellNumeric },
    { label: 'Camber', field: 'camber', component: EditableCellNumeric },
    { label: 'Caster', field: 'caster', component: EditableCellNumeric },
  ];

  const allFields = [
    ...defaultFields,
    { label: 'Axle Position', field: 'axle_position', component: EditableCell },
    { label: 'Axle Type', field: 'axle_type', component: EditableCell },
    { label: 'Carburetor', field: 'carburetor', component: EditableCell },
    { label: 'Comment', field: 'comment', component: EditableCell },
    { label: 'Curve Enter', field: 'curve_enter', component: EditableCell },
    { label: 'Heaviness', field: 'heaviness', component: EditableCell },
    { label: 'Max RPM', field: 'max_rpm', component: EditableCellNumeric },
    { label: 'Max Speed', field: 'max_speed', component: EditableCellNumeric },
    { label: 'Oversteer', field: 'oversteer', component: EditableCell },
    { label: 'Slip', field: 'slip', component: EditableCell },
    { label: 'Understeer', field: 'understeer', component: EditableCell },
    // Exclude 'surname', 'timestamp', 'name', 'session_group'
  ];

  const fieldsToShow = showAllFields ? allFields : defaultFields;

  return (
    <React.Fragment>
      {/* Fixed header row */}
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer'}}
        onClick={() => setOpen(!open)}
      >
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => deleteValue(id)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {rows[0].surname}
        </TableCell>
      </TableRow>

      {/* Collapsible content with scrollable table */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, overflowX: 'auto' }} ref={tableContainerRef}> {/* Make only this Box scrollable */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {canModify && (
                      <TableCell style={{ borderBottom: 'none' }}>
                        <IconButton
                          onClick={handleShowAllFieldsClick} // Updated onClick handler
                          aria-label="show all fields"
                          size="small"
                          style={{ marginLeft: 8 }}
                        >
                          {showAllFields ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </TableCell>
                    )}
                    {fieldsToShow.map((field) => (
                      <TableCell key={field.field}>{field.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((rowData, index) => (
                    <TableRow key={index}>
                      {canModify ? (
                        <TableCell style={{ verticalAlign: 'middle', borderBottom: 'none' }}>
                          <RemoveIcon
                            size="1.5rem"
                            className="remove-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShow(rowData.id);
                            }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                      {canModify ? (
                        fieldsToShow.map((field) => {
                          const Component = field.component;
                          const value = rowData[field.field];

                          let displayValue = value;

                          // Apply mapperYN to specific fields
                          if (
                            field.field === 'tires_new' ||
                            field.field === 'heaviness' ||
                            field.field === 'slip' ||
                            field.field === 'understeer' ||
                            field.field === 'oversteer' ||
                            field.field === 'curve_enter'
                          ) {
                            displayValue = mapperYN(value);
                          }

                          // Apply axle position mapping
                          if (field.field === 'axle_position') {
                            displayValue = axlePositionMapping[value];
                          }

                          // Define custom onSave function for specific fields
                          const onSave = (newValue) => {
                            let valueToSave = newValue;

                            if (
                              field.field === 'tires_new' ||
                              field.field === 'heaviness' ||
                              field.field === 'slip' ||
                              field.field === 'understeer' ||
                              field.field === 'oversteer' ||
                              field.field === 'curve_enter'
                            ) {
                              valueToSave = newValue === 'Yes' ? 1 : 0;
                            }

                            if (field.field === 'axle_position') {
                              valueToSave = reverseAxlePositionMapping[newValue];
                            }

                            updateValue(valueToSave, field.field, rowData.id);
                          };

                          return (
                            <Component
                              key={field.field}
                              value={displayValue}
                              onSave={onSave}
                              field={field.field}
                              id={rowData.id}
                            />
                          );
                        })
                      ) : (
                        <>
                          <TableCell>{rowData.session_number}</TableCell>
                          <TableCell>{rowData.laptime}</TableCell>
                          <TableCell align="right">{rowData.chassis_number}</TableCell>
                          <TableCell>{rowData.engine_number}</TableCell>
                          <TableCell align="right">{mapperYN(rowData.tires_new)}</TableCell>
                          <TableCell>{rowData.pressure_hot}</TableCell>
                          <TableCell align="right">{rowData.pressure_cold}</TableCell>
                          <TableCell>{rowData.crown}</TableCell>
                          <TableCell align="right">{rowData.pinion}</TableCell>
                          <TableCell>{rowData.min_rpm}</TableCell>
                          <TableCell align="right">{rowData.camber}</TableCell>
                          <TableCell align="right">{rowData.caster}</TableCell>
                          <TableCell align="right">{rowData.axle_position}</TableCell>
                          <TableCell align="right">{rowData.axle_type}</TableCell>
                          <TableCell>{rowData.carburetor}</TableCell>
                          <TableCell>{rowData.comment}</TableCell>
                          <TableCell align="right">{rowData.caster}</TableCell>
                          <TableCell align="right">{rowData.curve_enter}</TableCell>
                          <TableCell align="right">{rowData.heaviness}</TableCell>
                          <TableCell align="right">{rowData.max_rpm}</TableCell>
                          <TableCell align="right">{rowData.max_speed}</TableCell>
                          <TableCell align="right">{rowData.oversteer}</TableCell>
                          <TableCell align="right">{rowData.slip}</TableCell>
                          <TableCell align="right">{rowData.understeer}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}














const CollapsibleTable = () => {
  const [drivers, setDrivers] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [racingData, setRacingData] = useState([]);
  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  const [competitions, setCompetitions] = useState([]);
  const [competitionLoading, setCompetitionLoading] = useState(false);

  const onDriverChange = (selectedDrivers) => {
    setSelectedDrivers(selectedDrivers);
  };

  const onDayChange = (selectedDay) => {
    setDay(selectedDay);
  };

  const onRaceChange = (race) => {
    setRace(race);
    setSelectedDrivers([]);
  };

  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;

  useEffect(() => {
    getDrivers(false);
  }, [selectedTeam]);

  const getDrivers = async (filter, racingData) => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        if (showPersonalButton) {
          setDrivers(res.data);
          setSelectedDrivers([res.data[0].email]);
        } else {
          setDrivers(res.data);
        }

        if (filter) {
          const racingDataEmails = racingData.map((item) => item.email);
          const filteredDrivers = res.data.filter((driver) => racingDataEmails.includes(driver.email));
          setDrivers(filteredDrivers);
        }
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  const groupedData = racingData.reduce((acc, curr) => {
    if (!acc[curr.email]) {
      acc[curr.email] = [];
    }
    acc[curr.email].push(curr);
    return acc;
  }, {});
  let message;

  if (!race) {
    message = 'Please select a race';
  } else if (!day) {
    message = 'Please select a day';
  }

  useEffect(() => {
    setCompetitionLoading(true);
    const fetchCompetitions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`);
        const data = response.data;
        setCompetitions(data);
        setCompetitionLoading(false);
      } catch (error) {
        setCompetitionLoading(false);
        console.error("Error fetching competitions:", error);
      }
    };

    fetchCompetitions();
  }, [selectedTeam]);

  const getDataArchive = () => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_data_archive`, {
        params: {
          email: selectedDrivers.join(","),
          race,
          day,
          team_id: selectedTeam.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data !== null) {
          const sortedData = data.sort((a, b) => a.session_number - b.session_number);
          setRacingData(sortedData);
          if (selectedDrivers.length === 0) {
            getDrivers(true, sortedData);
          }
        }
      })
      .catch((error) => {
        console.error("Query Error:", error.response ? error.response.data : error.message);
      });
  };

  useEffect(() => {
    getDrivers(false);
  }, [selectedTeam]);

  useEffect(() => {
    if (race !== "") {
      getDataArchive();
    }
  }, [selectedDrivers, race, day]);

  const handleDeleteRow = (id) => {
    setRacingData((prevRacingData) => prevRacingData.filter((row) => row.id !== id));
  };

  const updateRows = (updatedRows) => {
    setRacingData((prevRacingData) =>
      prevRacingData.map((row) => updatedRows.find((r) => r.id === row.id) || row)
    );
  };

  return (
    <React.Fragment>
      <PageHeader
        drivers={drivers}
        onChange={onDriverChange}
        race={race}
        onDayChange={onDayChange}
        onRaceChange={onRaceChange}
        day={day}
        competitions={competitions}
        competitionLoading={competitionLoading}
      />
      {!(day && race) ? (
        <div className="grid-demo-no-data-container">
          {message && (
            <>
              <p className="grid-demo-no-data-text">{message}</p>
              <i className="grid-demo-info-icon fas fa-info-circle"></i>
            </>
          )}
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow></TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(groupedData).map((email, index) => (
                <Row
                  key={index}
                  rows={groupedData[email]}
                  onDelete={handleDeleteRow}
                  updateRows={updateRows}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};




export default observer(CollapsibleTable);