import { Box, Skeleton, Stack } from '@mui/material';
import { Button, Chip, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChatIcon from '@mui/icons-material/Chat';
import ChatMessage from 'components/Chat/Message';
import ChatMessageLoader from 'components/Chat/MessageLoader';
import ChatSuggestion from 'components/Suggestions';
import DOMPurify from 'dompurify';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Grid from '@mui/material/Grid';
import HttpProvider from 'services/HttpProvider'
import IconButton from '@mui/material/IconButton';
import Markdown from 'marked-react';
import Paper from '@mui/material/Paper';
import Pricing from '../Pricing';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Try_Drawer from './Try_Drawer';
import Typography from '@mui/material/Typography';
import Writer from 'components/Chat/Writer';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useLocation } from 'react-router-dom';

const chatTypes = [
    {
        title: 'AiMotor 1',
        description: 'Great for Personal Space',
        type: 'normal',
        icon: <FlashOnIcon fontSize="small" />,
        activeFor: ['Growth', 'Pro', 'Team'],
        userPlan: ['Growth'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        },
    },
    {
        title: 'AiMotor 2',
        description: 'Advanced model for Racing Teams',
        type: 'advanced',
        icon: <StarIcon fontSize="small" />,
        activeFor: ['Pro', 'Team'],
        userPlan: ['Growth', 'Pro', 'Team'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day: 1,
            day_team: 1,
        },
    },
];

const Chat = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [openPricing, setOpenPricing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chatType, setChatType] = useState(chatTypes[1]);
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const initialQuestion = query.get('question');
    const [currentStreamingMessage, setCurrentStreamingMessage] = useState('');
    const [incomingFragments, setIncomingFragments] = useState([]);
    const [isResponseStreaming, setIsResponseStreaming] = useState(false);
    const selectedChatId = '66de0882378af215137603b7';
    const [allChatMessages, setAllChatMessages] = useState({});
    const [conversation, setConversation] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleMenuItemClick = (model) => {
        const getChatToBeSelected = chatTypes.find((c) => c.title === model);

        if (getChatToBeSelected) {
            setChatType(getChatToBeSelected);
            handleClose();
        }
    };

    useEffect(() => {
        if (initialQuestion) {
            handleMessageSubmit(initialQuestion); // Automatically submit question from URL
        }
    }, [initialQuestion]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();  // Scroll to bottom whenever messages change
    }, [messages]);  // Depend on messages state

    const handleMessageComplete = (message, role = 'system') => {
        // Update the messages for display
        setMessages((prevMessages) => [...prevMessages, { message, role }]);
    
        // Update the conversation to include the system's response
        setConversation((prevConversation) => [
            ...prevConversation,
            { message, role }
        ]);
    
        // Clear streaming state
        setIncomingFragments([]);
        setIsResponseStreaming(false);
    };

    const handleMessageSubmit = async (message) => {
        const encodedMessage = encodeURIComponent(message);
        const newMessage = { role: 'user', message: message };
    
        // Add the user message to both `messages` and `conversation`
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setConversation((prevConversation) => [...prevConversation, newMessage]);
    
        setIsResponseStreaming(true);
        scrollToBottom();  // Scroll immediately after user message
    
        try {
            setIsLoading(true);
    
            // Replace this with the correct endpoint
            const endpoint = chatType.title === 'AiMotor 1' ? 'try_aimotor_1' : 'try_aimotor_2';
    
            await fetchEventSource(`${process.env.REACT_APP_API_DOMAIN}/${endpoint}?request=${encodedMessage}&conversation=${JSON.stringify(conversation)}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Accept: "text/event-stream",
                },
                onmessage(event) {
                    setIsLoading(false);
                    setIncomingFragments(prev => [...prev, JSON.parse(event.data)]);
                },
                onclose() {
                    setIncomingFragments(prev => [...prev, JSON.parse('"[DONE]"')]);
                    setIsLoading(false);
                },
                onerror(err) {
                    setIsLoading(false);
                    console.error('Error querying LLM:', err);
                },
            });
        } catch (error) {
            setIsLoading(false);
            console.error('Error querying LLM:', error);
        }
    };
    

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWriterComplete = () => {
        if (currentStreamingMessage) {
            setMessages((prevMessages) => [
                ...prevMessages,
                { message: currentStreamingMessage, role: 'system' },
            ]);

            setConversation((prevConversation) => [
                ...prevConversation,
                { message: currentStreamingMessage, role: 'system' },
            ]);
        }
        setIsResponseStreaming(false);
        setCurrentStreamingMessage('');
    };

    const resetChat = () => {
        setMessages([]);  // Clear the messages
        setConversation([]);  // Clear the conversation
        setCurrentStreamingMessage('');  // Clear the streaming message
        setIsResponseStreaming(false);  // Stop streaming response
    };



    return (
        <>
            <Box className="chat-wrapper" container={true} justifyContent="center">
                <Box sx={{ position: 'absolute', top: '16px', left: '16px', display: 'flex', alignItems: 'center' }}>
                    <Button
                        aria-controls={open ? 'version-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant="text"
                        sx={{ color: '#0D47A1', textTransform: 'none', fontSize: '22px', fontWeight: 'bold' }}
                    >
                        {chatType.title}
                        <ArrowDropDownIcon />
                    </Button>
                    <Menu
                        id="version-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ mt: 1 }}
                    >
                        {chatTypes.map((type) => (
                            <MenuItem key={type.title} onClick={() => handleMenuItemClick(type.title)}>
                                <ListItemIcon>
                                    {type.icon}
                                </ListItemIcon>
                                <ListItemText primary={type.title} secondary={type.description} />
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                <Box sx={{ height: "100%", width: '100%', pt: 10 }} display={'flex'} flexDirection={'column'} mx={'auto'}>
                    <div id="chat-container" style={{ flex: '1', overflowY: 'auto', textAlign: 'center', display: messages.length ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box>
                            <ChatSuggestion onClick={handleMessageSubmit} />
                        </Box>
                    </div>
                    <div
                        style={{ flex: '1', overflowY: 'auto', display: messages.length ? 'block' : 'none' }}
                        ref={chatContainerRef}
                    >
                        <Box sx={{
                            maxWidth: '800px',
                            margin: '0 auto'
                        }}>

                            {messages.map((msg, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    <ChatMessage owner={msg.role === "user"} message={msg.message} />
                                </div>
                            ))}
                            {isLoading && (
                                <ChatMessageLoader />
                            )}


                            {/* Show the streaming message while loading */}
                            {isResponseStreaming && !isLoading && (
                                <div style={{ marginBottom: '10px' }}>
                                    <Writer
                                        incomingFragments={incomingFragments}
                                        setIncomingFragments={setIncomingFragments}
                                        onComplete={(message) => handleMessageComplete(message)}
                                        scrollChat={scrollToBottom}
                                    />
                                </div>
                            )}
                        </Box>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const message = e.target.elements.message.value;
                            if (message.trim() !== '') {
                                handleMessageSubmit(message)
                                e.target.reset();
                            }
                        }}
                        style={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: 0, padding: '10px', zIndex: 2, width: '100%', maxWidth: '800px', margin: '0 auto' }}
                    >
                        <Paper
                            sx={{ p: '4px', borderRadius: "50px", display: 'flex', alignItems: 'center', width: '100%' }}
                        >
                            <Tooltip title="previous chat history">
                                <IconButton
                                    onClick={() => setIsDrawerOpen(true)}
                                    sx={{ color: 'white', backgroundColor: '#0D47A1', '&:hover': { backgroundColor: '#1565C0' }, padding: '6px' }}
                                >
                                    <ChatIcon sx={{ fontSize: '20px' }} />
                                </IconButton>
                            </Tooltip>

                            <TextField
                                fullWidth
                                placeholder="Message AiMotor"
                                variant="standard"
                                name="message"
                                multiline
                                InputLabelProps={{
                                    style: { color: 'black', textAlign: 'center', width: '100%' },
                                    shrink: true,
                                }}
                                InputProps={{
                                    style: { color: 'black', textAlign: 'center' },
                                    disableUnderline: true,
                                }}
                                sx={{ textAlign: 'center' }}
                            />
                            <IconButton
                                color="primary"
                                type="submit"
                                style={{ minWidth: 'auto' }}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                        </Paper>
                    </form>

                    {isMobile && (
                        <Chip
                            label={"AIMOTOR TEAM"}
                            sx={{
                                display: 'block',
                                margin: '0 auto',
                                textAlign: 'center',
                                color: 'white',
                                backgroundColor: '#dee2e6',
                                fontWeight: 'bold',
                                padding: '5px 5px',
                                borderRadius: '22px',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                            }}
                        />
                    )}
                </Box>

                <Try_Drawer
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    onNewChat={resetChat}  // Pass resetChat as a prop
                />

                <Pricing open={openPricing} onClose={() => setOpenPricing(false)} />
            </Box>
        </>
    );
};

export default Chat;
