import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import Skeleton from '@mui/material/Skeleton';
import axios from "axios";

const LapTable = ({ drivers, race, day, fullName, laps, setLaps, selectedDriverEmail, sessionNumbersByDriver, driverSessions }) => {
  const [rows, setRows] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [compare, setCompare] = useState({});
  console.log('real dataset', dataset)
  const [isLoading, setIsLoading] = useState(false);
  const sessionNumber = sessionNumbersByDriver[selectedDriverEmail] 
  ? sessionNumbersByDriver[selectedDriverEmail][sessionNumbersByDriver[selectedDriverEmail].length - 1] 
  : 1;

  const getTelemetryTable = async () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/telemetry_table`, {
        params: {
          driver_emails: selectedDriverEmail,
          race,
          session: sessionNumber,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setDataset(res.data);
        console.log("dataset table fetched", res.data);
        console.log('selected driver email is', selectedDriverEmail);

        // Find the data corresponding to the selected driver and session number
        const data = res.data?.find(item => item.sessionNumber === sessionNumber && item.email === selectedDriverEmail);

        // Format the data into rows if it exists
        const formattedRows = data ? data.laps.map((lapData) => ({
          Lap: lapData.lap,
          Email: data.email,
          'Lap Time': lapData.laptime,
          // 'Split 1': lapData.Split_1,
          // 'Split 2': lapData.Split_2,
          // 'Split 3': lapData.Split_3,
          // 'Split 4': lapData.Split_4,
          'Max RPM': lapData.MaxRpm,
          'Max Speed': lapData.MaxSpeed,
          'Max TK': lapData.MaxTK,
        })) : [];

        // Update the rows state with the formatted rows
        setRows(formattedRows);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Axios Error:", error);
      });
  };

  useEffect(() => {
    getTelemetryTable();
    // Update laps based on selected session and driver
    {/*const selectedDriverSessions = driverSessions?.find(driver => driver.email === selectedDriverEmail);
    if (selectedDriverSessions) {
      const sessionData = selectedDriverSessions.sessions?.find(session => session.session === sessionNumber);
      if (sessionData) {
        setLaps([{ email: selectedDriverEmail, laps: sessionData.laps }]);
      }
    }*/}
  }, [sessionNumber, race, drivers, selectedDriverEmail, driverSessions]);

  const handleCompareChange = (lap) => {

    setCompare((prev) => ({
      ...prev,
      [lap]: !prev[lap]
    }));

    setLaps((prevLaps) => {
      const existingEntry = prevLaps?.find(entry => entry.email === selectedDriverEmail);
      if (existingEntry) {
        const updatedLaps = existingEntry.laps.includes(lap)
          ? existingEntry.laps.filter(l => l !== lap)
          : [...existingEntry.laps, lap];

        return prevLaps.map(entry =>
          entry.email === selectedDriverEmail
            ? { ...entry, laps: updatedLaps }
            : entry
        );
      } else {
        return [...prevLaps, { email: selectedDriverEmail, laps: [lap] }];
      }
    });
  };

  return (
    isLoading ? (
      <Skeleton sx={{height:'70vh', marginTop: -18, marginBottom:-15}}/>
    ) : (
      <Box>
        <TableContainer component={Paper}  style={{
    width: '100%',
    maxHeight: 'calc(100vh - 200px)', // Adjust this value as needed for your layout
    overflowY: 'auto' // Enables scrolling if content overflows
  }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lap</TableCell>
                <TableCell>Lap Time</TableCell>
                <TableCell>Max RPM</TableCell>
                <TableCell>Max Speed</TableCell>
                <TableCell>Max TK</TableCell>
                <TableCell>Compare</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.Lap}</TableCell>
                  <TableCell>{row['Lap Time']}</TableCell>
                  <TableCell>{row['Max RPM']}</TableCell>
                  <TableCell>{row['Max Speed']}</TableCell>
                  <TableCell>{row['Max TK']}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={laps.some(entry => entry.email === selectedDriverEmail && entry.laps.includes(row.Lap))}
                      onChange={() => handleCompareChange(row.Lap)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  );
};

export default LapTable;
