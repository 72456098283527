import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ResetPasswordForm from '../ResetPasswordForm';

const ResetPassword = () => {
//   const location = useLocation();
//   const [accessToken, setAccessToken] = useState(""); 

//   useEffect(() => {
//     const searchParams = new URLSearchParams(location.search);
//     setAccessToken(searchParams.get('access_token'));
//     // Now, you have the access token, and you can proceed with the password reset logic.
//     console.log(accessToken);
//     axios.get('https://aimotor.eu/api/users/me', {  
//         headers: {
//         'Authorization': `Bearer ${accessToken}`, // Include the access token in the header
//         'accept': 'application/json',
//         },
//     })
//     .then(userResponse => {
//         const userData = userResponse.data;
//         localStorage.setItem('user_data', JSON.stringify(userData));
//     });
//   }, [location.search]);

  // Rest of the component logic...
  debugger
  return(
    <>
    
      <ResetPasswordForm/>
    </>
  );
};

export default ResetPassword;
