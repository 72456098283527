import './components.css';

import { Box, Button, Chip, Container, Grid, Stack, Typography } from '@mui/material';

import { Link } from "react-router-dom";
import React from 'react';
import myUserStore from 'stores/UserStore';
import { observer } from 'mobx-react-lite';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function SecondPartHome() {
    const isLoggedIn = myUserStore.isLoggedIn

    const isMobile = useMediaQuery('(max-width: 600px)');

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Box className="second-part-home">
            <Container maxWidth={false} className='container-lg'>
            <Grid container rowSpacing={{ xs: '160px', lg: '200px' }}>
            <Grid item container spacing={{ xs: 6, lg: 5 }} alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={10} sm={12} md={12} lg={11}>
                    <Box textAlign={{ xs: 'center'  }} mr="auto">
                        <div>
                        <Stack direction="row" spacing={1}  className="hero-btns" justifyContent={"center"} hidden={isMobile}>
                        <Link to="/racing-space-try" className="insertdata">
                            <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
                                Try Free
                            </Button>
                        </Link>
                        <Link to="/ai-product" className="insertdata">
                            <Button variant="text" color="light">
                                Learn More
                            </Button>
                        </Link>
                    </Stack>
                            
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={12} md={12} lg={11

                }>
                    <img 
                        src={isMobile ? "images/1_picture_mob.jpg" : "images/5_picture_pc.jpg"} 
                        alt={"gif"} 
                        style={{ width: "100%", marginBottom:"35px"}} 
                        className="content-gif" 
                    />
              
                    <Stack direction="row" spacing={1} className="hero-btns" justifyContent={"center"}  hidden={isMobile}>
                        <Link to="/racing-space-try" className="insertdata">
                            <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
                                Try Free
                            </Button>
                        </Link>
                        <Link to="/ai-product" className="insertdata">
                            <Button variant="text" color="light">
                                Learn More
                            </Button>
                        </Link>
                    </Stack>
           
                </Grid>
            </Grid>
        
            </Grid>
            <Grid item container spacing={{ xs: 6, lg: 5 }} alignItems={'center'} justifyContent={'center'}>
  <Grid item xs={10} sm={12} md={12} lg={11}>
    <Box textAlign={{ xs: 'center' }} mr="auto">
      <div>
        <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center' }} hidden={!isMobile} mb={5.375}>
          <Link to="/racing-space-try" className="insertdata">
            <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
              Try Free
            </Button>
          </Link>
          <Link to="/ai-product" className="insertdata">
            <Button variant="text" color="light">
              Learn More
            </Button>
          </Link>
        </Stack>
      </div>
    </Box>
  </Grid>
  <Grid item xs={10} sm={12} md={12} lg={11}>
    <img 
      src={isMobile ? "images/2_picture_mob.jpg" : "images/1_picture_pc.jpg"} 
      alt={"gif"} 
      style={{ width: "100%", marginBottom:"35px" }} 
      className="content-gif" 
    />
    <div>
      <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center' }} hidden={!isMobile} mb={6.375}>
        <Link to="/racing-space-try" className="insertdata">
          <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
            Try Free
          </Button>
        </Link>
        <Link to="/ai-product" className="insertdata">
          <Button variant="text" color="light">
            Learn More
          </Button>
        </Link>
      </Stack>
    </div>
  </Grid>
  <Grid item container spacing={{ xs: 6, lg: 5 }} alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={10} sm={12} md={12} lg={11}>
                    <Box textAlign={{ xs: 'center'  }} mr="auto">
                        <div>
                        <Stack direction="row" spacing={1}  className="hero-btns" justifyContent={"center"} hidden={isMobile}>
                        <Link to="/racing-space-try" className="insertdata">
                            <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
                                Try Free
                            </Button>
                        </Link>
                        <Link to="/ai-product" className="insertdata">
                            <Button variant="text" color="light">
                                Learn More
                            </Button>
                        </Link>
                    </Stack>
                            
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={12} md={12} lg={11}>
                    <img 
                        src={isMobile ? "images/3_picture_mob.jpg" : "images/4_picture_pc.jpg"} 
                        alt={"gif"} 
                        style={{ width: "100%", marginBottom:"35px"}} 
                        className="content-gif" 
                    />
              
                    <Stack direction="row" spacing={1} className="hero-btns" justifyContent={"center"}  hidden={isMobile}>
                        <Link to="/racing-space-try" className="insertdata">
                            <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
                                Try Free
                            </Button>
                        </Link>
                        <Link to="/ai-product" className="insertdata">
                            <Button variant="text" color="light">
                                Learn More
                            </Button>
                        </Link>
                    </Stack>
           
                </Grid>
            </Grid>
        
            </Grid>
            <Grid item container spacing={{ xs: 6, lg: 5 }} alignItems={'center'} justifyContent={'center'}>
  <Grid item xs={10} sm={12} md={12} lg={11}>
    <Box textAlign={{ xs: 'center' }} mr="auto">
      <div>
        <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center' }} hidden={!isMobile} mb={5.375}>
          <Link to="/racing-space-try" className="insertdata">
            <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
              Try Free
            </Button>
          </Link>
          <Link to="/ai-product" className="insertdata">
            <Button variant="text" color="light">
              Learn More
            </Button>
          </Link>
        </Stack>
      </div>
    </Box>
  </Grid>
  <Grid item xs={10} sm={12} md={12} lg={11}>
    <img 
      src={isMobile ? "images/4_picture_mob.jpg" : "images/3_picture_pc.jpg"} 
      alt={"gif"} 
      style={{ width: "100%", marginBottom:"35px" }} 
      className="content-gif" 
    />
    <div>
      <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center' }} hidden={!isMobile} mb={6.375}>
        <Link to="/racing-space-try" className="insertdata">
          <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
            Try Free
          </Button>
        </Link>
        <Link to="/ai-product" className="insertdata">
          <Button variant="text" color="light">
            Learn More
          </Button>
        </Link>
      </Stack>
    </div>
  </Grid>

  <Grid item container spacing={{ xs: 6, lg: 5 }} alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={10} sm={12} md={12} lg={11}>
                    <Box textAlign={{ xs: 'center'  }} mr="auto">
                        <div>
                        <Stack direction="row" spacing={1}  className="hero-btns" justifyContent={"center"} hidden={isMobile}>
                        <Link to="/racing-space-try" className="insertdata">
                            <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
                                Try Free
                            </Button>
                        </Link>
                        <Link to="/ai-product" className="insertdata">
                            <Button variant="text" color="light">
                                Learn More
                            </Button>
                        </Link>
                    </Stack>
                            
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={12} md={12} lg={11}>
                    <img 
                        src={isMobile ? "images/5_picture_mob.jpg" : "images/2_picture_pc.jpg"} 
                        alt={"gif"} 
                        style={{ width: "100%", marginBottom:"35px"}} 
                        className="content-gif" 
                    />
              
                    <Stack direction="row" spacing={1} className="hero-btns" justifyContent={"center"}  hidden={isMobile}>
                        <Link to="/racing-space-try" className="insertdata">
                            <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
                                Try Free
                            </Button>
                        </Link>
                        <Link to="/ai-product" className="insertdata">
                            <Button variant="text" color="light">
                                Learn More
                            </Button>
                        </Link>
                    </Stack>
           
                </Grid>
            </Grid>
        
            </Grid>
            <Grid item container spacing={{ xs: 6, lg: 5 }} alignItems={'center'} justifyContent={'center'}>
  <Grid item xs={10} sm={12}  md={12} lg={11}>
    <Box textAlign={{ xs: 'center' }} mr="auto">
      <div>
        <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center' }} hidden={!isMobile} mb={5.375}>
          <Link to="/racing-space-try" className="insertdata">
            <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
              Try Free
            </Button>
          </Link>
          <Link to="/ai-product" className="insertdata">
            <Button variant="text" color="light">
              Learn More
            </Button>
          </Link>
        </Stack>
      </div>
    </Box>
  </Grid>
  <Grid item xs={10} sm={12}  md={12} lg={11}>
    <img 
      src={isMobile ? "images/6_picture_mob.jpg" : "images/6_picture_pc.jpg"} 
      alt={"gif"} 
      style={{ width: "100%", marginBottom:"35px" }} 
      className="content-gif" 
    />
    <div>
      <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center' }} hidden={!isMobile} mb={6.375}>
        <Link to="/racing-space-try" className="insertdata">
          <Button color="secondary" variant="contained" onClick={() => console.log("hey")}>
            Try Free
          </Button>
        </Link>
        <Link to="/ai-product" className="insertdata">
          <Button variant="text" color="light">
            Learn More
          </Button>
        </Link>
      </Stack>
    </div>
  </Grid>
                </Grid> 


                    {/* non credo abbia senso ripeterlo pure qui, anche perche riporta l'utente nuovamente su */}
                    {/* <Stack direction="row" spacing={1} sx={{ flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 2 }}>
                    <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" clickable sx={{ color: 'white' }} />
                    <Chip label="For Racing Teams" id="For Team Managers" component="a" href='#For Team Managers' variant="outlined" color='primary' clickable />
                </Stack> */}
            </Container>
        </Box>
    );
}

export default observer(SecondPartHome);