import Menu, { MenuProps } from '@mui/material/Menu';
import React, { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';
import InsertData from "./InsertData.js";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArchiveIcon from '@mui/icons-material/Archive';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import ManagerForm from "../ManagerForm.js";
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Chip from '@mui/material/Chip';
import CorniceFront from './CorniceFront';
import CornicePressure from './CornicePressure';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FormControl from '@mui/material/FormControl';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Mmenu from '@mui/material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OutlinedInput from '@mui/material/OutlinedInput'; // Corrected import path for OutlinedInput component
import { PieChart } from '@mui/x-charts/PieChart';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { useTheme } from '@mui/material/styles';
import InsertDriver from './InsertDriver.js';
import GraphTelemetry from './GraphTelemetry.js';
import TelemetryArchive from './TelemetryArchive.js';


const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};









function getStyles(name, personName, theme) { // Pass 'name' and 'personName' as arguments
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}




function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PieChartWithSelect = ({ driver, race, day, driverSessions, hoveredData, setHoveredData, trackIndex,setTrackIndex, collapsed }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState(0);
  const [anchorElTeamAnalysis, setAnchorElTeamAnalysis] = useState(null);
  const [selectedOptionTeamAnalysis, setSelectedOptionTeamAnalysis] = useState('');
  const [competitions, setCompetitions] = useState([]);
  const [racingData, setRacingData] = useState([]);

  const [showFront, setShowFront] = useState(false);

  const [showPressure, setShowPressure] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [emailName, setEmailName] = useState([]);

  const [showEngine, setShowEngine] = useState(true); // Use showEngine for engine graphs
  const [selectedDate, setSelectedDate] = useState(null);
  const [competitionLoading, setCompetitionLoading] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0)
    setShowFront(newValue === 1)
    setShowPressure(newValue === 2)
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleMenuClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-calendar-popover' : undefined;











  //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;
  console.log("selected team", selectedTeam.id)
  





  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

console.log('Race selected Telemetry PArent:', race);
console.log('Day selected Telemetry PArent:', day);
console.log('Drivers selected Telemetry PArent:', driver);



  return (

    <div>





                 
      <Box sx={{ width: '100%', marginTop: collapsed ? 10 : 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="fullWidth" value={value} onChange={handleChangeTab} >
            <Tab label="DELTA TIME GRAPH" {...a11yProps(0)} />
            <Tab label="SETUP" {...a11yProps(1)} />

          </Tabs>
          <div className='graphs2'>
          <>
            {showEngine && <GraphTelemetry race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day} driverSessions={driverSessions} hoveredData={hoveredData} setHoveredData={setHoveredData}   setTrackIndex={setTrackIndex}/>}
            {showFront && <TelemetryArchive race1={race} day1={day} drivers1={driver}/>}

          </>

        </div>
        </Box>
   

      </Box>








    </div>
  );
};

export default observer(PieChartWithSelect);